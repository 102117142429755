<!--
 * @Author: DY
 * @Date: 2021-05-20 13:55:20
 * @LastEditTime: 2021-06-08 16:32:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test_gx\src\views\productionManagement\productionDeliverGoodsJournal\logList.vue
-->
<template>
    <div class="shippingLogList" v-loading="loading">
        <div class="header">
            <h4>混凝土生产原始记录</h4>
            <div class="qRCode iconfont iconerweima" @click="generateQRcode"></div>
        </div>
        <div class="shippingLogList_content" id="out-table">
            <div class="essentialInfo">
                <table class="border_table">
                    <thead>
                        <tr>
                            <th>生产日期：</th>
                            <td>{{ essentialInfo.product_time||'-' }}</td>
                            <th>生产机组：</th>
                            <td>{{ essentialInfo.line_name||'-' }}</td>
                            <th>任务单号：</th>
                            <td>{{ essentialInfo.task_number||'-' }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>合同单位</th>
                            <td>{{ essentialInfo.customer_name||'-' }}</td>
                            <th>运输车号</th>
                            <td>{{ essentialInfo.shch||'-' }}</td>
                            <th>发货单号</th>
                            <td>{{ essentialInfo.pcb_number||'-' }}</td>
                        </tr>
                        <tr>
                            <th>关联发货单</th>
                            <td colspan="5">
                                <span v-if="target_pcb_number" class="link-text" @click="viewRelationPcb(target_pcb_number)">
                                    {{ target_pcb_number }}
                                </span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th>工程名称</th>
                            <td>{{ essentialInfo.project_name||'-' }}</td>
                            <th>驾驶员</th>
                            <td>{{ essentialInfo.sjxm||'-' }}</td>
                            <th>出厂时间</th>
                            <td>{{ essentialInfo.record_print_time||'-' }}</td>
                        </tr>
                        <tr>
                            <th>施工部位</th>
                            <td>{{ essentialInfo.place_detail||'-' }}</td>
                            <th>运距</th>
                            <td>{{ essentialInfo.haul_distance||'-' }}</td>
                            <th>出场过磅时间</th>
                            <td>{{ essentialInfo.out_weight_time||'-' }}</td>
                        </tr>
                        <tr>
                            <th>砼型号</th>
                            <td>{{ essentialInfo.strength_grade_name||'-' }}</td>
                            <th>机操员</th>
                            <td>{{ essentialInfo.sign_product||'-' }}</td>
                            <th>车辆皮重</th>
                            <td>{{ essentialInfo.tare_weight||'-' }}<span class="unit">kg</span></td>
                        </tr>
                        <tr>
                            <th>坍落度</th>
                            <td>{{ essentialInfo.slump }}±{{ essentialInfo.slump_name }}</td>
                            <th>质检员</th>
                            <td>{{ essentialInfo.sign_formula||'-' }}</td>
                            <th>出厂毛重</th>
                            <td>{{ essentialInfo.gross_weight||'-' }}<span class="unit">kg</span></td>
                        </tr>
                        <tr>
                            <th>生产方量</th>
                            <td>{{ essentialInfo.filled_volume||'-' }}</td>
                            <th>调度员</th>
                            <td>{{ essentialInfo.sign_off||'-' }}</td>
                            <th>出厂净重</th>
                            <td>{{ essentialInfo.net_weight||'-' }}<span class="unit">kg</span></td>
                        </tr>
                        <tr>
                            <th>发货方量</th>
                            <td>{{ essentialInfo.volume||'-' }}</td>
                            <th>气    温</th>
                            <td>{{ essentialInfo.temperature ||'-' }}<span class="unit">℃</span></td>
                            <th>配比容重</th>
                            <td>{{ essentialInfo.volume_weight||'-' }}<span class="unit">kg</span></td>
                        </tr>
                        <tr>
                            <th>累计方量</th>
                            <td>
                                {{ essentialInfo.grand_total_volume||'-' }}
                            </td>
                            <th>累计车次</th>
                            <td>
                                {{ essentialInfo.grand_total_vehicles||'-' }}
                            </td>
                            <th>实际容重</th>
                            <td>{{ essentialInfo.unit_weight||'-' }}<span class="unit">kg</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="printingData">
                <table class="border_table">
                    <tbody>
                        <tr>
                            <th rowspan="3">
                                盘次
                            </th>
                            <th>原材料</th>
                            <template v-for="(item, index) in tableHeader">
                                <td :key="index" :colspan="item.modelItems.length">
                                    {{ item.title }}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <th>规格</th>
                            <template v-for="(item, index) in orderDictionary">
                                <td :key="index">
                                    {{ item.value }}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <th>配比值</th>
                            <template v-for="(k, i) in orderDictionary">
                                <td :key="`pbz_${i}`">
                                    {{ formulaValues[k.name] }}
                                </td>
                            </template>
                        </tr>
                        <template v-for="(item, index) in diskData">
                            <tr :key="`pc_${index}`">
                                <th>盘次：{{ item.pc }}</th>
                                <td>设定值</td>
                                <td v-for="(k, i) in orderDictionary" :key="`pcs_${i}`">
                                    {{ item.recordset[k.name] ? item.recordset[k.name][0] : '-' }}
                                </td>
                            </tr>
                            <tr :key="`duration_${index}`">
                                <th>搅拌时间：{{ item.duration }}s</th>
                                <td>称量值</td>
                                <td v-for="(k, i) in orderDictionary" :key="`durations_${i}`">
                                    {{ item.recordset[k.name] ? item.recordset[k.name][1] : '-' }}
                                </td>
                            </tr>
                            <tr :key="`cpfl_${index}`">
                                <th>搅拌方量：{{ item.cpfl }}</th>
                                <td>误差%</td>
                                <td
                                    v-for="(k, i) in orderDictionary"
                                    :key="`cpfls_${i}`"
                                    :class="{'hight-cell':item.recordset[k.name]?item.recordset[k.name][3]=='1':false}"
                                >
                                    {{ item.recordset[k.name] ? item.recordset[k.name][2] : '-' }}
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <th rowspan="3">
                                累计
                            </th>
                            <td>
                                设定值
                            </td>
                            <template v-for="(k, i) in orderDictionary">
                                <td :key="`total1_${i}`">
                                    {{ totalMap[k.name][0] }}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td>
                                称量值
                            </td>
                            <template v-for="(k, i) in orderDictionary">
                                <td :key="`total2_${i}`">
                                    {{ totalMap[k.name][1] }}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td>
                                误差%
                            </td>
                            <template v-for="(k, i) in orderDictionary">
                                <td :key="`total3_${i}`" :class="{'hight-cell':totalMap[k.name]?totalMap[k.name][3]=='1':false}">
                                    {{ totalMap[k.name][2] }}
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="bottom_info">
                <table>
                    <tbody>
                        <tr>
                            <th>【来自】发货单：</th>
                            <td>
                                <span
                                    v-if="essentialInfo.turn_pcb_number || essentialInfo.transfer_turn_pcb_number"
                                    class="link-text"
                                    @click="viewRelationPcb(essentialInfo.turn_pcb_number || essentialInfo.transfer_turn_pcb_number)"
                                >
                                    {{ essentialInfo.turn_pcb_number || essentialInfo.transfer_turn_pcb_number }}
                                </span>
                                <span v-else>-</span>
                            </td>
                            <th>【转发】发货单：</th>
                            <td>
                                <span
                                    v-if="essentialInfo.back_pcb_number || essentialInfo.transfer_back_pcb_number"
                                    class="link-text"
                                    @click="viewRelationPcb(essentialInfo.back_pcb_number || essentialInfo.transfer_back_pcb_number)"
                                >
                                    {{ essentialInfo.back_pcb_number || essentialInfo.transfer_back_pcb_number }}
                                </span>
                                <span v-else>-</span>
                            </td>
                            <th>【来自】转机组：</th>
                            <td>
                                {{ essentialInfo.changed_line_name||'-' }}
                            </td>
                        </tr>
                        <tr>
                            <th>到达工地时间：</th>
                            <td>
                                {{ essentialInfo.arrival_time||'-' }}
                            </td>
                            <th>开始卸料时间：</th>
                            <td>
                                {{ essentialInfo.unload_time||'-' }}
                            </td>
                            <th>结束卸料时间：</th>
                            <td>
                                {{ essentialInfo.unload_over_time||'-' }}
                            </td>
                        </tr>
                        <tr>
                            <th>回场时间：</th>
                            <td>
                                {{ essentialInfo.into_time||'-' }}
                            </td>
                            <th>回场重量：</th>
                            <td>
                                {{ essentialInfo.back_gross_weight||'-' }}
                            </td>
                            <th>回场称重：</th>
                            <td>
                                {{ essentialInfo.back_net_weight||'-' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <footer>
            <el-button @click="exportData">
                导出
            </el-button>
            <el-button @click="cancel">
                关闭
            </el-button>
            <el-button @click="closeAll" v-if="extr.openList">
                全部关闭
            </el-button>
        </footer>
        <el-dialog
            title="扫描二维码"
            :visible.sync="dialogVisible"
            append-to-body
            width="240px"
            :before-close="handleClose"
            custom-class="qrCodeDivDialog"
        >
            <div id="qrCode" ref="qrCodeDiv"></div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import utils from '@/utils/util';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
    // 生产发货日志列表
    name: 'shipping-log-list',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            isReload: true,
            // 基本信息数据
            essentialInfo: {},
            // 打料数据
            printingData: {},
            // 打料列表表头数据
            tableHeader: [],
            // 打料列表循环字典
            orderDictionary: [],
            // 打料列表配比值数据
            formulaValues: {},
            // 打料列表盘次数据
            diskData: [],
            // 关联单号
            target_pcb_number: '',

            // 二维码弹出
            dialogVisible: false,

            // 累计数据
            totalMap: {},

        };
    },
    computed: {},
    watch: {},
    created() {

        // 初始化数据
        this.getInitData();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 关闭出层
        cancel() {
            this.$parent.hide();
        },
        closeAll() {
            this.$parent.hide();
            (this.extr.openList || []).forEach(item => {
                item.hide();
            });
        },
        // 初始化数据
        getInitData() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/production/productrecord/recordset/${this.extr.pcb_number}`)
                .then(res => {
                    if (res) {
                        this.target_pcb_number = res.target_pcb_number;
                        this.essentialInfo = res.recordInfo;
                        if (res.recordConsumes) {
                            this.printingData = res.recordConsumes;
                            if (res.recordConsumes.recordConsumesHeader) {
                                if (res.recordConsumes.recordConsumesHeader.recordHeaders) {
                                    this.tableHeader = res.recordConsumes.recordConsumesHeader.recordHeaders;
                                    const row = [];
                                    this.tableHeader.forEach(item => {
                                        row.push(...item.modelItems);
                                    });
                                    row.forEach(item => {
                                        this.orderDictionary.push({
                                            name: item.name,
                                            value: item.value,
                                        });
                                    });
                                }
                                if (res.recordConsumes.recordConsumesHeader.formulaValues) {
                                    this.formulaValues = res.recordConsumes.recordConsumesHeader.formulaValues;
                                }
                            }
                            if (res.recordConsumes.recordConsumesEntity) {
                                if (res.recordConsumes.recordConsumesEntity.plate_record_consumes) {
                                    this.diskData = res.recordConsumes.recordConsumesEntity.plate_record_consumes;
                                }
                                if (res.recordConsumes.recordConsumesEntity.grand_total_record_consume) {
                                    this.totalMap = res.recordConsumes.recordConsumesEntity.grand_total_record_consume;
                                }
                            }
                        }
                    } else if (this.isReload) {
                        this.isReload = false;
                        this.getInitData();
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生成二维码
        generateQRcode() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                new QRCode(this.$refs.qrCodeDiv, {
                    text: this.essentialInfo.pcb_number || '',
                    width: 200,
                    height: 200,
                    colorDark: '#333333', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
                });
            });
        },
        // 查看关联发货单
        viewRelationPcb(pcb_nubmer) {
            const openList = [...this.extr.openList || []];
            openList.push(this.$parent);
            this.$toast({
                title: true,
                text: '业务信息',
                type: 'eject',
                width: '80%',
                height: '90%',
                t_url: 'productionManagement/productionDeliverGoodsJournal/logList',
                extr: {
                    pcb_number: pcb_nubmer,
                    openList: openList,
                },
            });
        },
        // 关闭二维码弹出层
        handleClose(done) {
            done();
            this.$('#qrCode').html('');
        },
        // 后端导出数据
        exportData() {
            this.loading = true;
            this.$axios
                .post('/interfaceApi/production/productrecord/recordset/export/' + this.extr.pcb_number, {}, { responseType: 'arraybuffer' })
                .then(res => {
                    this.loading = false;
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const fileName = `混凝土生产原始记录-${utils.currentTimeFormat('yyyy-MM-dd HH:mm:ss')}.xls`;
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.shippingLogList
    height 100%
    padding 0.2rem
    .header
        height 0.44rem
        position relative
        margin-bottom 0.14rem
        h4
            font-size 0.2rem
            line-height 0.44rem
            text-align center
            color #333333

        .qRCode
            width 0.44rem
            height 0.44rem
            position absolute
            right 0
            top 0
            font-size 0.38rem
            cursor pointer
    .shippingLogList_content
        height calc(100% - 1.1rem)
        overflow-x hidden
        overflow-y auto
        table
            width calc(100% - 2px)
            box-sizing border-box
            border-collapse collapse
            th,td
                font-size 0.14rem
                height 0.36rem
                font-weight 400
            th
                color #002B94
                width 1.5rem
            td
                color #333333
                width 3.5rem
                .link-text
                    color: blue;
                    text-decoration: underline;
                    cursor: pointer;
            &.border_table
                tbody
                    th,td
                        border 1px solid #D7D7D7
                        th
                            background #F6F8FC
            .hight-cell
                background: #FFF1F0;
        .essentialInfo
            tbody
                th
                    background #F6F8FC
            td
                padding 0.03rem 0.1rem
                .unit
                    color #7D7D7D
                    float right
                    margin-right 0.14rem
        .printingData
            margin-top 0.15rem
            table
                width 100%
                th,td
                    text-align center
                    border 1px solid #D7D7D7
                th
                    background #F6F8FC
                td
                    width 1rem
                    table
                        td,th
                            border-top none
                            border-right none
                            &:first-child
                                border-left none
                        tr
                            &:last-child
                                td,th
                                    border-bottom none
                tbody
                    tr
                        &:nth-child(3n+1)
                            th,td
                                border-top 1px double #979EA7
                        &:first-child
                            th,td
                                border-top 1px solid #979EA7
            >table
                >tbody
                    >th
                        border-left 1px solid #979EA7
                    >td
                        &:last-child
                            border-right 1px solid #979EA7
            .total-th
                height 100%;
                display flex
                justify-content center
                align-items center
                border-top: 1px solid #979ea7
    footer
        width 100%
        position absolute
        bottom 0.2rem
        left 0
        text-align center
        .el-button
            width 2rem
            padding 0
            font-size 0.16rem
            line-height 0.4rem
            margin 0 0.2rem
            height 0.4rem
/deep/ .qrCodeDivDialog
            .el-dialog__body
                padding 10px 20px 20px
</style>
